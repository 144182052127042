import payload_plugin_e81h812gyu from "/home/debian/hanabi-live.com/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_xqFnhsGnH6 from "/home/debian/hanabi-live.com/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_gdkdabs2zpraxqbhhkx5mwcdea/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ePVfPmcuLY from "/home/debian/hanabi-live.com/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_gdkdabs2zpraxqbhhkx5mwcdea/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_HsPJ5kcyAt from "/home/debian/hanabi-live.com/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_gdkdabs2zpraxqbhhkx5mwcdea/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_x6O6qF8GPg from "/home/debian/hanabi-live.com/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@2.4._5g3fxqnemkmiejye5nr6nszvpe/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_dYmxrqVnxl from "/home/debian/hanabi-live.com/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_gdkdabs2zpraxqbhhkx5mwcdea/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HP96b65Qvr from "/home/debian/hanabi-live.com/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_gdkdabs2zpraxqbhhkx5mwcdea/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dh1DjQKDhQ from "/home/debian/hanabi-live.com/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_gdkdabs2zpraxqbhhkx5mwcdea/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_cQMIfor4jw from "/home/debian/hanabi-live.com/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_gdkdabs2zpraxqbhhkx5mwcdea/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_76k3KHBGNm from "/home/debian/hanabi-live.com/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/home/debian/hanabi-live.com/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_MgSv8BSBdi from "/home/debian/hanabi-live.com/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_gdkdabs2zpraxqbhhkx5mwcdea/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_9lTDLN3npl from "/home/debian/hanabi-live.com/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@2.4.2__y5jwjsj7wwplx3wtlpgskajacm/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_3jLkir4oLn from "/home/debian/hanabi-live.com/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@2.4.2__y5jwjsj7wwplx3wtlpgskajacm/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_BRYM33JNeB from "/home/debian/hanabi-live.com/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@2.4.2__y5jwjsj7wwplx3wtlpgskajacm/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import plugin_2uDlL6SEBX from "/home/debian/hanabi-live.com/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_magicast@0.3.5_rollup@4.32.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import slideovers_6Ib42mwcxh from "/home/debian/hanabi-live.com/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_rollup@4.32.1_typescript@5.7.3_vite@6.0.11_@_tlidky2b45k3vufisclr7cvkau/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_o63x2VXpaS from "/home/debian/hanabi-live.com/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_rollup@4.32.1_typescript@5.7.3_vite@6.0.11_@_tlidky2b45k3vufisclr7cvkau/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_pvNw1BcrtU from "/home/debian/hanabi-live.com/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_rollup@4.32.1_typescript@5.7.3_vite@6.0.11_@_tlidky2b45k3vufisclr7cvkau/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_O82MEhe1N4 from "/home/debian/hanabi-live.com/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.32.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_qFNo8Me6Ct from "/home/debian/hanabi-live.com/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@2.4.2_sty_5jge5g7rlwbs6wcuud6tfunxby/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import nuxt_Ul89UDgRb4 from "/home/debian/hanabi-live.com/modules/sentry/runtime/plugins/nuxt.ts";
import scrollbars_client_vCf0KjYPJ2 from "/home/debian/hanabi-live.com/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_patch_hash=6yfz4t4nqf2zhrydpe7j6i2j3a_change-case@5.4.4_magicast@0.3.5_rol_rppubw24tvvdjskwlce63qrmxy/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_hTGvr8P0N7 from "/home/debian/hanabi-live.com/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_patch_hash=6yfz4t4nqf2zhrydpe7j6i2j3a_change-case@5.4.4_magicast@0.3.5_rol_rppubw24tvvdjskwlce63qrmxy/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_uXnj34RK29 from "/home/debian/hanabi-live.com/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_patch_hash=6yfz4t4nqf2zhrydpe7j6i2j3a_change-case@5.4.4_magicast@0.3.5_rol_rppubw24tvvdjskwlce63qrmxy/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import defaults_dcrq9wAFrp from "/home/debian/hanabi-live.com/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@2.4.2__y5jwjsj7wwplx3wtlpgskajacm/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_e81h812gyu,
  revive_payload_client_xqFnhsGnH6,
  unhead_ePVfPmcuLY,
  router_HsPJ5kcyAt,
  _0_siteConfig_x6O6qF8GPg,
  payload_client_dYmxrqVnxl,
  navigation_repaint_client_HP96b65Qvr,
  check_outdated_build_client_dh1DjQKDhQ,
  chunk_reload_client_cQMIfor4jw,
  plugin_vue3_76k3KHBGNm,
  components_plugin_zlvi6dcIsi,
  prefetch_client_MgSv8BSBdi,
  siteConfig_9lTDLN3npl,
  inferSeoMetaPlugin_3jLkir4oLn,
  titles_BRYM33JNeB,
  plugin_2uDlL6SEBX,
  slideovers_6Ib42mwcxh,
  modals_o63x2VXpaS,
  colors_pvNw1BcrtU,
  plugin_client_O82MEhe1N4,
  plugin_qFNo8Me6Ct,
  nuxt_Ul89UDgRb4,
  scrollbars_client_vCf0KjYPJ2,
  presets_hTGvr8P0N7,
  variables_uXnj34RK29,
  defaults_dcrq9wAFrp
]