
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93TVbaRV0LC4Meta } from "/home/debian/hanabi-live.com/app/pages/blog/[slug].vue?macro=true";
import { default as indexwnGhse8byFMeta } from "/home/debian/hanabi-live.com/app/pages/blog/index.vue?macro=true";
import { default as addressGHurVKxumzMeta } from "/home/debian/hanabi-live.com/app/pages/dashboard/address.vue?macro=true";
import { default as indexsWghbbShPpMeta } from "/home/debian/hanabi-live.com/app/pages/dashboard/index.vue?macro=true";
import { default as _91id_93Ddv4Bb2CYOMeta } from "/home/debian/hanabi-live.com/app/pages/dashboard/order/[id].vue?macro=true";
import { default as indexn1E0mlhjFLMeta } from "/home/debian/hanabi-live.com/app/pages/dashboard/order/index.vue?macro=true";
import { default as indexbOEwEPx53eMeta } from "/home/debian/hanabi-live.com/app/pages/index.vue?macro=true";
import { default as loginjhw8nHp0RoMeta } from "/home/debian/hanabi-live.com/app/pages/login.vue?macro=true";
import { default as _91slug_93g8OwpKU0DIMeta } from "/home/debian/hanabi-live.com/app/pages/member/[slug].vue?macro=true";
import { default as indexzuK9N3iLD2Meta } from "/home/debian/hanabi-live.com/app/pages/member/index.vue?macro=true";
export default [
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/home/debian/hanabi-live.com/app/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexwnGhse8byFMeta || {},
    component: () => import("/home/debian/hanabi-live.com/app/pages/blog/index.vue")
  },
  {
    name: "dashboard-address",
    path: "/dashboard/address",
    meta: addressGHurVKxumzMeta || {},
    component: () => import("/home/debian/hanabi-live.com/app/pages/dashboard/address.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexsWghbbShPpMeta || {},
    component: () => import("/home/debian/hanabi-live.com/app/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-order-id",
    path: "/dashboard/order/:id()",
    meta: _91id_93Ddv4Bb2CYOMeta || {},
    component: () => import("/home/debian/hanabi-live.com/app/pages/dashboard/order/[id].vue")
  },
  {
    name: "dashboard-order",
    path: "/dashboard/order",
    meta: indexn1E0mlhjFLMeta || {},
    component: () => import("/home/debian/hanabi-live.com/app/pages/dashboard/order/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexbOEwEPx53eMeta || {},
    component: () => import("/home/debian/hanabi-live.com/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginjhw8nHp0RoMeta || {},
    component: () => import("/home/debian/hanabi-live.com/app/pages/login.vue")
  },
  {
    name: "member-slug",
    path: "/member/:slug()",
    component: () => import("/home/debian/hanabi-live.com/app/pages/member/[slug].vue")
  },
  {
    name: "member",
    path: "/member",
    meta: indexzuK9N3iLD2Meta || {},
    component: () => import("/home/debian/hanabi-live.com/app/pages/member/index.vue")
  }
]