import routerOptions0 from "/home/debian/hanabi-live.com/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_gdkdabs2zpraxqbhhkx5mwcdea/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/debian/hanabi-live.com/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_patch_hash=6yfz4t4nqf2zhrydpe7j6i2j3a_change-case@5.4.4_magicast@0.3.5_rol_rppubw24tvvdjskwlce63qrmxy/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}